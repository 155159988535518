import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { ProgressBar } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import 'bootstrap/dist/css/bootstrap.min.css'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import WestSharpIcon from '@mui/icons-material/WestSharp'
import EastSharpIcon from '@mui/icons-material/EastSharp'
import CurrencyExchangeSharpIcon from '@mui/icons-material/CurrencyExchangeSharp'
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined'
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp'
import TerminalSharpIcon from '@mui/icons-material/TerminalSharp'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp'
import image from '../../attorneys.png'
import { StepContent } from './index.style'

import { CONFIG_STEPS, ADDITIONAL_RULES } from '../../form_configuration/default_config'
import {
    emailValidation,
    phoneValidation,
    setPhoneMask,
    send_conversion_event,
    send_tracking_events,
    setAnalyticsFormFields
} from './_utils'

interface IForm {

}

function MultiStep() {
    const [showModal, setShowModal] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [isFormValid, setIsFormValid] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [name, setName] = useState('')
    const [steps, setSteps] = useState(CONFIG_STEPS)
    const [errorMessage, setErrorMessage] = useState('')
    const [token, setToken] = useState('')
    const [tooltipContentTitle, setTooltipContentTitle] = useState('')
    const [tooltipContentDescription, setTooltipContentDescription] = useState('')
    const [showTooltipContent, setShowTooltipContent] = useState(false)
    const [target, setTarget] = useState(null)
    const [activeNumber, setActiveNumber] = useState(0)
    const ref = useRef(null)
    const [hiddenTooltip, setHiddenTooltip] = useState(false)
    const isMobile = useMediaQuery({ query: `(max-width: 1199px)` })

    const handleClose = () => setShowModal(false)

    let steps_counter = steps.length

    useEffect(() => {
        let modalButtons = document.querySelectorAll('.open-multistep-modal')
        let inputName = document.querySelector('input[name="name"]')

        if (modalButtons) {
            modalButtons.forEach(modalButton => {
                modalButton.addEventListener(
                    'click',
                    () => {
                        setShowModal(true)

                        let placementValue = 'widget'
                        let elementType = (modalButton.tagName).toLowerCase() === 'button' ? 'button' : 'link'

                        if (modalButton instanceof HTMLElement && modalButton.dataset.placement) {
                            placementValue = modalButton.dataset.placement
                        }

                        send_tracking_events(
                            'open-modal',
                            '',
                            {
                                'placement': `${placementValue}`,
                                'element-action': '',
                                'element-type': `${elementType}`,
                                'value': '',
                                'step': ''
                            }
                        )
                    }
                );
            })
        }

        if (inputName) {
            inputName.addEventListener(
                'change',
                () => {
                    // @ts-ignore
                    setName(inputName.value)
                }
            )
        }

        let modalTooltip = document.querySelector('.multistep-modal--tooltip')

        modalTooltip && modalTooltip.addEventListener(
            'click',
            (event) => {
                // @ts-ignore
                if (event.target && event.target.className === 'multistep-modal--tooltip') {
                    setHiddenTooltip(true)
                    setTimeout(() => {
                        setShowTooltipContent(false)
                    }, 300);
                }
            }
        )

        let otherFields = document.querySelectorAll('.form--other')
        otherFields.forEach(field => {
            let input = field.querySelector('.form-check-input')

            field.addEventListener(
                'click',
                (event) => {
                    // @ts-ignore
                    if (event.target.classList.contains('form-text')) {
                        // @ts-ignore
                        input.checked = true
                        field.classList.add('form--other-checked')
                        setIsFormValid(true)
                    }
                }
            )
        })
    })

    useEffect(() => {
        checkFormValidation()
    }, [activeStep])

    const progress_percentage = (activeStep/(steps_counter + 1))*100

    const changeActiveStep = (newValue: number) => {
        setActiveStep(newValue)
    }

    const checkEmailValidation = () => {
        const emailField = document.querySelector('.modal-step:not(.hidden) input[name="email"]')

        // @ts-ignore
        if (!emailField || !emailField.value) {
            return true
        }
        // @ts-ignore
        if (emailValidation(emailField.value)) {
            setErrorMessage('')
            // @ts-ignore
            emailField.closest('.input-group').classList.remove('field--error')
            return true
        } else {
            setErrorMessage('Fill correct email address')
            setIsFormValid(false)
            // @ts-ignore
            emailField.closest('.input-group').classList.add('field--error')

            setTimeout(() => {
                setErrorMessage('')
            },5000)

            return false
        }
    }

    const checkPhoneValidation = () => {
        const phoneField = document.querySelector('.modal-step:not(.hidden) input[name="phone"]')

        // @ts-ignore
        if (!phoneField || !phoneField.value) {
            return true
        }

        setPhoneMask(phoneField)
        // @ts-ignore
        if (phoneValidation(phoneField.value)) {
            setErrorMessage('')
            // @ts-ignore
            phoneField.closest('.input-group').classList.remove('field--error')
            return true
        } else {
            setErrorMessage('Fill correct phone number.')
            setIsFormValid(false)
            // @ts-ignore
            phoneField.closest('.input-group').classList.add('field--error')

            setTimeout(() => {
                setErrorMessage('')
            },5000)

            return false
        }
    }

    const checkFormValidation = () => {
        const form = document.querySelector(`.step__form_${activeStep}`)
        let shouldSkip = false
        let shouldSkipRequired = false

        if (form) {
            const fields = form.querySelectorAll('.form--multioption')
            const requiredFields = form.querySelectorAll('.form__field[required]')
            const inputTextFields = form.querySelectorAll('.form-text')
            const textareaFields = form.querySelectorAll('.form-textarea')

            textareaFields.forEach(field => {
                // @ts-ignore
                if (field.value.trim() !== "") {
                    setIsFormValid(true)
                    field.classList.add('active-field')
                } else {
                    setIsFormValid(true)
                }

                field.addEventListener(
                    'blur',
                    () => {
                        // @ts-ignore
                        if (field.value.trim() !== "") {
                            field.classList.add('checked-field')
                        } else {
                            field.classList.remove('checked-field')
                        }
                    }
                )
            })

            inputTextFields.forEach(field => {
                // @ts-ignore
                if (field.value.trim() !== "") {
                    setIsFormValid(true)
                    field.classList.add('active-field')

                    // @ts-ignore
                    field.closest('.input-group') && field.closest('.input-group').classList.add('active-field')
                } else {
                    setIsFormValid(false)
                    field.classList.remove('active-field')
                    // @ts-ignore
                    field.closest('.input-group') && field.closest('.input-group').classList.remove('active-field')
                }

                field.addEventListener(
                    'blur',
                    () => {
                        // @ts-ignore
                        if (field.value.trim() !== "") {
                            field.classList.add('checked-field')

                            // @ts-ignore
                            field.closest('.input-group') && field.closest('.input-group').classList.add('checked-field')
                        }
                    }
                );
            })

            fields.forEach(field => {
                if (field.classList.contains('form--other')) {
                    if (field.querySelectorAll('input')[0].checked) {
                        field.classList.add('form--other-checked')
                        setIsFormValid(true)
                        shouldSkip = true
                        return
                    } else {
                        field.classList.remove('form--other-checked')
                    }
                }

                if (field.classList.contains('form-text')) {
                    // @ts-ignore
                    if (field.value.trim() !== "") {
                        setIsFormValid(true)
                        shouldSkip = true
                        return
                    }
                }

                if (field.classList.contains('form-check') && field.querySelectorAll('input')[0].checked) {
                    setIsFormValid(true)
                    shouldSkip = true
                    return
                }

                if (shouldSkip) {
                    return
                }

                setIsFormValid(false)
            })

            requiredFields.forEach(field => {
                if (field.classList.contains('form-check') && !field.querySelectorAll('input')[0].checked) {
                    setIsFormValid(false)
                    shouldSkipRequired = true
                    return
                }

                if (field.classList.contains('form-text')) {
                    // @ts-ignore
                    if (field.value.trim() === "") {
                        setIsFormValid(false)
                        shouldSkipRequired = true
                        return
                    }
                }

                if (!checkPhoneValidation() && checkEmailValidation()) {
                    setIsFormValid(false)
                }
            })
        }
    }

    const sendForm = (event: any, formStep: number, newStep: number) => {
        event.preventDefault()

        const form = document.querySelector(`.step__form.step__form_${formStep}`)
        // @ts-ignore
        const modalStepActive = form.closest('.modal-step')
        // @ts-ignore
        modalStepActive.classList.add('.step--active')

        // @ts-ignore
        const data = new FormData(form)
        const formJSON = Object.fromEntries(data.entries())

        for (const [key, value] of Object.entries(formJSON)) {
            // @ts-ignore
            let allData = data.getAll([key])
            // @ts-ignore
            if (data.getAll([key]).length > 1) {
                if (allData.includes('Other')) {
                    allData[allData.length - 2] = allData[allData.length - 2] + ": " + allData[allData.length - 1]
                    allData.pop()
                } else {
                    allData.pop()
                }
                // @ts-ignore
                formJSON[key] = allData
            }
        }

        //ADDITIONAL_RULES(JSON.stringify(formJSON))

        let ajaxHeaders = {}
        if (token) {
            ajaxHeaders = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        if (formStep === steps_counter && newStep > steps_counter) {
            $.ajax({
                type: "POST",
                data: JSON.stringify({...formJSON, ...setAnalyticsFormFields()}),
                url: "https://crm-api.jpnt.tech/api/form",
                headers: ajaxHeaders,
                success: function (data, textStatus, jqXHR) {
                    setIsFormSubmitted(true)

                    let conversion = localStorage.getItem("conversion");
                    if (!conversion) {
                        localStorage.setItem("conversion", JSON.stringify(data));
                    }
                    send_conversion_event(true, 'multistep-form', JSON.stringify(data));
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("submit conversion failed")
                }
            });
        } else {
            $.ajax({
                type: "PUT",
                data: JSON.stringify(formJSON),
                url: "https://crm-api.jpnt.tech/api/form",
                headers: ajaxHeaders,
                success: function (data, textStatus, jqXHR) {
                    if (!token) setToken(data.token)
                    changeActiveStep(newStep)
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log("submit conversion failed");
                }
            });
        }
    }

    const handleButtonClick = (event: any) => {
        setTarget(event.target)
        setShowTooltipContent(!showTooltipContent)
    }

    // @ts-ignore
    return (
        <StepContent
            className={classNames('multistep', {'hidden': !showModal})}
            id="multistep-form-modal"
            ref={ref}
        >
            <div className="multistep-modal">
                {!isFormSubmitted && (
                    <>
                        <div
                            className={classNames('step__header', {'hidden' : activeStep === 0 })}
                        >
                            <ProgressBar
                                className="step__progress-bar"
                                now={progress_percentage}
                            />
                        </div>
                        <button
                            type="button"
                            className={classNames('step__close', {'hidden' : activeStep === 0 })}
                            aria-label="Close"
                            onClick={() => {
                                handleClose()

                                send_tracking_events(
                                    'close',
                                    '',
                                    {
                                        'placement': 'multistep-modal',
                                        'element-type': 'button',
                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                    }
                                )
                            }}
                        ><CloseSharpIcon/></button>
                    </>
                )}

                {!isFormSubmitted && (<div
                    className={classNames('modal-step modal-step--first', {'step--hidden' : activeStep !== 0 })}
                >
                    <button
                        type="button"
                        className="step__close"
                        aria-label="Close"
                        onClick={() => {
                            handleClose()

                            send_tracking_events(
                                'close',
                                '',
                                {
                                    'placement': 'multistep-modal',
                                    'element-type': 'button',
                                    'step': 'intro'
                                }
                            )
                        }}
                    ><CloseSharpIcon/></button>
                    <div className="step__content">
                        <h2 className="step__intro">We’ll help you understand if your case qualifies for <strong>financial support</strong></h2>
                        <img src={image} className="step__image" height="56" alt="People faces" />
                        <div className="step__points">
                            <div className="step__point">
                                <TimerSharpIcon/>
                                <p><strong>Complete this form.</strong> Answer a few short questions about your case in less than a minute</p>
                            </div>
                            <div className="step__point">
                                <PhoneCallbackOutlinedIcon/>
                                <p><strong>Answer our intake call.</strong> Meet your legal team within minutes</p>
                            </div>
                            <div className="step__point">
                                <CurrencyExchangeSharpIcon/>
                                <p><strong>Get payouts.</strong> 2x higher than average</p>
                            </div>
                        </div>
                    </div>
                </div>)}

                {!isFormSubmitted && steps.map((step: any, index: number) => <div
                    className={classNames('modal-step', {'step--hidden' : activeStep !== index + 1 })}
                    key={ index + 1 }
                >
                    <div className="step__content">
                        <p className="step__question" dangerouslySetInnerHTML={{__html: step.question}}></p>
                        {step.description && <p className="step__description">{ step.description }</p>}
                        <Form
                            className={classNames(`step__form step__form_${index + 1}`, {'step__form--wrap' : step.answers[0].fields && step.answers[0].fields.length > 2 })}
                            id={`step__form step__form_${index + 1}`}
                            onSubmit={(event) => event.preventDefault()}

                        >
                            {step.answers.map((answer: any, index: number) => {
                                if (answer.type === 'multioption') {
                                    return (
                                        answer.fields.map((subfield: any, index: number) => {
                                            if (subfield.type === 'radio_with_text' || subfield.type === 'checkbox_with_text') {
                                                return (
                                                    <Form.Check
                                                        className="form__field form--multioption form--other"
                                                        type={subfield.type === 'radio_with_text' ? "radio" : "checkbox"}
                                                        id={`${answer.name}_${index}`}
                                                        key={index}
                                                        required={subfield.required}
                                                    >
                                                        <Form.Check.Input
                                                            type={subfield.type === 'radio_with_text' ? "radio" : "checkbox"}
                                                            name={answer.name}
                                                            value={subfield.text}
                                                            onChange={(event) => {
                                                                checkFormValidation()
                                                                send_tracking_events(
                                                                    'choose-value',
                                                                    `${answer.name}`,
                                                                    {
                                                                        'placement': 'multistep-modal',
                                                                        'element-action': `${event.target.checked ? 'check' : 'uncheck'}`,
                                                                        'element-type': `${subfield.type === 'radio_with_text' ? "radio" : "checkbox"}`,
                                                                        'value': `${subfield.text}`,
                                                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                        <Form.Check.Label>{subfield.text}</Form.Check.Label>

                                                        <Form.Control
                                                            className="form__field form-text form--multioption"
                                                            type="text"
                                                            placeholder="Please specify"
                                                            onBlur={(event) => {
                                                                checkFormValidation()

                                                                send_tracking_events(
                                                                    'choose-value',
                                                                    `${answer.name}`,
                                                                    {
                                                                        'placement': 'multistep-modal',
                                                                        'element-action': 'check',
                                                                        'element-type': `${subfield.type === 'radio_with_text' ? "radio" : "checkbox"}`,
                                                                        'value': `Other: ${event.target.value}`,
                                                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                                    }
                                                                )
                                                            }}
                                                            name={answer.name}
                                                            required={subfield.required}
                                                        />
                                                    </Form.Check>
                                                )
                                            }

                                            if (subfield.type === 'checkbox') {
                                                // @ts-ignore
                                                return (
                                                    <Form.Check
                                                        className="form__field form--multioption"
                                                        type="checkbox"
                                                        id={`${answer.name}_${index}`}
                                                        key={index}
                                                        required={subfield.required}
                                                    >
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            name={answer.name}
                                                            value={subfield.text}
                                                            onChange={(event) => {
                                                                checkFormValidation()

                                                                send_tracking_events(
                                                                    'choose-value',
                                                                    `${answer.name}`,
                                                                    {
                                                                        'placement': 'multistep-modal',
                                                                        'element-action': `${event.target.checked ? 'check' : 'uncheck'}`,
                                                                        'element-type': 'checkbox',
                                                                        'value': `${subfield.text}`,
                                                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                        <Form.Check.Label>{subfield.text}</Form.Check.Label>
                                                        {subfield.tooltip && (<>
                                                            {!isMobile && (<Overlay
                                                                show={activeNumber === index && showTooltipContent}
                                                                target={target}
                                                                placement="bottom"
                                                                container={ref}
                                                                >
                                                                <Popover id="popover-contained">
                                                                    <Popover.Body>
                                                                        <p>{subfield.text}</p>
                                                                        <p>{subfield.tooltip}</p>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            </Overlay>)}
                                                            <div
                                                                className="field__tooltip"
                                                                onClick={(event) => {
                                                                    if (!isMobile) {
                                                                        // @ts-ignore
                                                                        setActiveNumber(index)
                                                                        handleButtonClick(event)
                                                                    } else {
                                                                        setTooltipContentTitle(subfield.text)
                                                                        setTooltipContentDescription(subfield.tooltip)
                                                                        setHiddenTooltip(false)
                                                                        setShowTooltipContent(true)
                                                                    }
                                                                }}
                                                                onMouseEnter={(event) => {
                                                                    if (!isMobile) {
                                                                        // @ts-ignore
                                                                        setActiveNumber(index)
                                                                        handleButtonClick(event)
                                                                    }
                                                                }}
                                                                onMouseLeave={(event) => {
                                                                    if (!isMobile) {
                                                                        // @ts-ignore
                                                                        setActiveNumber(0)
                                                                        setShowTooltipContent(false)
                                                                    }
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon/>
                                                            </div>
                                                        </>)}
                                                    </Form.Check>
                                                )
                                            }

                                            if (subfield.type === 'text') {
                                                return (
                                                    <Form.Control
                                                        className="form__field form-text form--multioption"
                                                        type="text"
                                                        placeholder={subfield.text}
                                                        onChange={() => {
                                                            checkFormValidation()
                                                        }}
                                                        onBlur={() => {
                                                            send_tracking_events(
                                                            'choose-value',
                                                            `${answer.name}`,
                                                        {
                                                            'placement': 'multistep-modal',
                                                            'element-action': '',
                                                            'element-type': 'text',
                                                            'value': `${subfield.text}`,
                                                            'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                        }
                                                            )
                                                        }}
                                                        name={answer.name}
                                                        required={subfield.required}
                                                    />
                                                )
                                            }

                                            if (subfield.type === 'radio') {
                                                return (
                                                    <Form.Check
                                                        className="form__field form--multioption"
                                                        type="radio"
                                                        label={subfield.text}
                                                        value={subfield.text}
                                                        id={`${answer.name}_${index}`}
                                                        key={index}
                                                        name={answer.name}
                                                        required={subfield.required}
                                                        onChange={() => {
                                                            checkFormValidation()

                                                            send_tracking_events(
                                                                'choose-value',
                                                                `${answer.name}`,
                                                                {
                                                                    'placement': 'multistep-modal',
                                                                    'element-action': 'check',
                                                                    'element-type': 'radio',
                                                                    'value': `${subfield.text}`,
                                                                    'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                                }
                                                            )
                                                        }}
                                                    />
                                                )
                                            }
                                        })
                                    )
                                }
                                if (answer.type === 'radio') {
                                    return (
                                        <Form.Check
                                            className="form__field"
                                            type="radio"
                                            label={answer.text}
                                            value={answer.text}
                                            id={`${answer.name}_${index}`}
                                            key={index}
                                            name={answer.name}
                                            required={answer.required}
                                            onChange={() => {
                                                checkFormValidation()

                                                send_tracking_events(
                                                    'choose-value',
                                                    `${answer.name}`,
                                                    {
                                                        'placement': 'multistep-modal',
                                                        'element-action': 'check',
                                                        'element-type': 'radio',
                                                        'value': `${answer.text}`,
                                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                    }
                                                )
                                            }}
                                        />
                                    )
                                }

                                if (answer.type === 'textarea') {
                                    return (
                                        <FloatingLabel label={answer.name}>
                                            <Form.Control as="textarea"
                                                className="form__field form-textarea"
                                                rows={4}
                                                placeholder={answer.text}
                                                required={answer.required}
                                                name={answer.name}
                                                onChange={() => {
                                                    checkFormValidation()
                                                }}
                                                onBlur={(event) => {
                                                  send_tracking_events(
                                                      'choose-value',
                                                      `${answer.name}`,
                                                      {
                                                          'placement': 'multistep-modal',
                                                          'element-action': '',
                                                          'element-type': 'textarea',
                                                          'value': `${event.target.value}`,
                                                          'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                      }
                                                  )
                                                }}
                                                style={{ height: '140px' }}
                                            />
                                        </FloatingLabel>
                                    )
                                }

                                if (answer.type === 'text') {
                                    return (
                                        <Form.Control
                                            className="form__field form-text"
                                            type="text"
                                            placeholder={answer.text}
                                            onChange={() => {
                                                checkFormValidation()
                                            }}
                                            onBlur={(event) => {
                                                send_tracking_events(
                                                    'choose-value',
                                                    `${answer.name}`,
                                                    {
                                                        'placement': 'multistep-modal',
                                                        'element-action': '',
                                                        'element-type': 'text',
                                                        'value': `${event.target.value}`,
                                                        'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                    }
                                                )
                                            }}
                                            name={answer.name}
                                            required={answer.required}
                                        />
                                    )
                                }

                                if (answer.type === 'phone') {
                                    return (
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                                            <Form.Control
                                                className="form__field form-text"
                                                placeholder="(999) 999-9999"
                                                type="tel"
                                                required={answer.required}
                                                onChange={() => {
                                                    checkFormValidation()
                                                    checkPhoneValidation()
                                                }}
                                                onBlur={(event) => {
                                                    send_tracking_events(
                                                        'choose-value',
                                                        `${answer.name}`,
                                                        {
                                                            'placement': 'multistep-modal',
                                                            'element-action': '',
                                                            'element-type': 'text',
                                                            'value': `${event.target.value}`,
                                                            'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                        }
                                                    )
                                                }}
                                                name={answer.name}
                                            />
                                        </InputGroup>
                                    )
                                }

                                if (answer.type === 'email') {
                                    return (
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1"><MailOutlineSharpIcon/></InputGroup.Text>
                                            <Form.Control
                                                className="form__field form-text"
                                                placeholder={answer.text}
                                                type="text"
                                                required={answer.required}
                                                onChange={() => {
                                                    checkFormValidation()
                                                    checkEmailValidation()
                                                }}
                                                onBlur={(event) => {
                                                    send_tracking_events(
                                                        'choose-value',
                                                        `${answer.name}`,
                                                        {
                                                            'placement': 'multistep-modal',
                                                            'element-action': '',
                                                            'element-type': 'text',
                                                            'value': `${event.target.value}`,
                                                            'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                                        }
                                                    )
                                                }}
                                                name={answer.name}
                                            />
                                        </InputGroup>
                                    )
                                }
                            })}
                        </Form>
                        {errorMessage && (<div className="error_content">
                            <WarningAmberSharpIcon/>
                            <span>{errorMessage}</span>
                        </div>)}
                    </div>
                </div>)}

                {!isFormSubmitted && activeStep === 0 && (
                    <div
                        className={classNames('step__footer', {'step__footer--intro' : activeStep === 0 })}
                    >
                        <Button
                            className="step__start"
                            variant="primary"
                            size="lg"
                            onClick={() => {
                                changeActiveStep(1)

                                send_tracking_events(
                                    'next-step',
                                    '',
                                    {
                                        'placement': 'multistep-modal',
                                        'element-type': 'button',
                                        'step': 'intro'
                                    }
                                )
                            }}
                        >Get started <EastSharpIcon/></Button>
                        <p className="step__disclaimer">By submitting your information you indicate that you've read
                            and agree to
                            our <a href="https://justpoint.com/disclaimer" target="_blank">disclaimer</a> & <a href="https://justpoint.com/privacy-policy" target="_blank">privacy policy</a>.</p>

                    </div>
                )}

                {!isFormSubmitted && activeStep !== 0 && (<div className="step__footer">
                    <Button
                        className={`step__back ${CONFIG_STEPS[activeStep - 1]['step_name']}_step__back`}
                        variant="primary"
                        size="lg"
                        onClick={(event) => {
                            if (activeStep !== 0) {
                                sendForm(event, activeStep, activeStep - 1)
                            }
                            send_tracking_events(
                                'prev-step',
                                '',
                                {
                                    'placement': 'multistep-modal',
                                    'element-type': 'button',
                                    'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                }
                            )
                        }}
                        type="submit"
                        form={`step__form step__form_${activeStep + 1}`}
                    ><WestSharpIcon/></Button>
                    <Button
                        className={`step__next ${CONFIG_STEPS[activeStep - 1]['step_name']}_step__next`}
                        variant="primary"
                        size="lg"
                        onClick={(event) => {
                            if (activeStep !== 0) {
                                sendForm(event, activeStep, activeStep + 1)
                            }
                            send_tracking_events(
                                'next-step',
                                '',
                                {
                                    'placement': 'multistep-modal',
                                    'element-type': 'button',
                                    'step': `${CONFIG_STEPS[activeStep - 1]['step_name']}`
                                }
                            )
                        }}
                        disabled={!isFormValid}
                        type="submit"
                        form={`step__form step__form_${activeStep + 1}`}
                    >{activeStep === steps_counter ? `Submit` : `Continue`}{activeStep !== steps_counter && (<EastSharpIcon/>)}</Button>
                </div>)}

                {isFormSubmitted && (
                    <>
                        <div className="modal-step modal-step--confirmation">
                            <button
                                type="button"
                                className="step__close"
                                aria-label="Close"
                                onClick={() => {
                                    handleClose()

                                    send_tracking_events(
                                        'close',
                                        '',
                                        {
                                            'placement': 'multistep-modal',
                                            'element-type': 'button',
                                            'step': 'confirmation'
                                        }
                                    )
                                }}
                            ><CloseSharpIcon/></button>
                            <div className="step__content">
                                <div className="confirmation__intro">
                                    <div className="intro__icon">
                                        <PhoneCallbackOutlinedIcon/>
                                    </div>
                                    <p className="intro__title">
                                        <span>Thank you, {name}</span><br/>
                                        We'll call you in few minutes.
                                    </p>
                                    <p className="intro__description">
                                        In this call, we’ll conduct an initial assessment to understand your case
                                    </p>
                                </div>
                                <div className="confirmation__steps">
                                    <h2 className="confirmation__title">What happens next?</h2>
                                    <div className="confirmation__content">
                                        <div className="confirmation__step">
                                            <div className="step__progress">
                                                <div className="step__icon"><TerminalSharpIcon/></div>
                                                <div className="step__line"></div>
                                            </div>
                                            <div className="step__p">
                                                <p><span>Our legal team will analyze your case details</span> to determine
                                                    if you qualify for compensation and identify the best course of legal
                                                    action for your specific case</p>
                                            </div>
                                        </div>
                                        <div className="confirmation__step">
                                            <div className="step__progress">
                                                <div className="step__icon"><BusinessCenterOutlinedIcon/></div>
                                                <div className="step__line"></div>
                                            </div>
                                            <div className="step__p">
                                                <p><span>We match you to the right attorney</span> who will personally
                                                    represent you, pursue your case in court, and support you every step
                                                    of the way</p>
                                            </div>
                                        </div>
                                        <div className="confirmation__step">
                                            <div className="step__progress">
                                                <div className="step__icon"><TaskAltOutlinedIcon/></div>
                                            </div>
                                            <div className="step__p">
                                                <p><span>Judgment or Settlement.</span> If there is a successful outcome,
                                                    you will receive compensation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {isMobile && showTooltipContent && (<div className="multistep-modal--tooltip">
                <div
                    className={classNames('multistep-modal--tooltip__content', {'tooltip--hidden': hiddenTooltip})}
                >
                    <button
                        type="button"
                        className="multistep-modal--tooltip__close"
                        aria-label="Close"
                        onClick={() => {
                            setHiddenTooltip(true)
                            setTimeout(() => {
                                setShowTooltipContent(false)
                            }, 300);
                        }}
                    ><CloseSharpIcon/></button>
                   <p className="content__title">{tooltipContentTitle}</p>
                   <p className="content__description">{tooltipContentDescription}</p>
                </div>
            </div>)}
        </StepContent>
    )
}

export default MultiStep
